import React from 'react';
import { Helmet } from 'react-helmet';
import Maincss from '../../styles/main';
import { Body, Container } from '../../styles/styles';

import { useStaticQuery, graphql } from 'gatsby'; // eslint-disable-line

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet title={data.site.siteMetadata.title} />
      <Body>
        <Container>
          {children}
        </Container>
      </Body>
      <Maincss />
    </>
  );
}
